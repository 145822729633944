import request from "@/utils/request";

// 获取table
export function getTable(params) {
  return request({
    url: "/role/role-list",
    method: "Get",
    params: params,
  });
}

// 切换角色状态
export function switchRoleStatus(params) {
  return request({
    url: "/role/status",
    method: "Patch",
    params: params,
  });
}

// 添加角色
export function addRole(requestBody) {
  return request({
    url: "/role",
    method: "Post",
    data: requestBody,
  });
}

// 修改角色
export function editRole(params, requestBody) {
  return request({
    url: "/role",
    method: "Patch",
    params: params,
    data: requestBody,
  });
}

// 删除角色
export function deleteRole(params) {
  return request({
    url: "/role",
    method: "Delete",
    params: params,
  });
}

// 获取角色名列表
export function getRoleNames() {
  return request({
    url: "/role/role-names",
    method: "Get",
  });
}

// 为角色分配权限
export function bindingRoleAuthority(requestBody) {
  return request({
    url: "/role/role-authority",
    method: "Post",
    data: requestBody,
  });
}

// 获取角色权限
export function getRoleAuthority(params) {
  return request({
    url: "/role/role-authority",
    method: "Get",
    params: params,
  });
}
