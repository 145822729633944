<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="分配权限"
    >
      <el-tree
        ref="tree"
        :data="data"
        show-checkbox
        node-key="id"
        :default-expanded-keys="defaultExpandedKeys"
        :default-checked-keys="defaultCheckedKeys"
        :props="defaultProps"
      >
      </el-tree>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { bindingRoleAuthority, getRoleAuthority } from "@/api/role";

export default {
  name: "AuthorityTree",
  data() {
    return {
      visible: false,
      defaultExpandedKeys: [
        "sys",
        "log",
        "room",
        "info",
        "statistic",
        "data",
        "info:company",
        "info:monitory-point",
        "info:pollution",
      ],
      defaultCheckedKeys: [],
      data: [
        {
          id: "data",
          label: "数据管理",
          authorityName: "data",
          children: [
            {
              id: "data:real",
              label: "实时监控",
              authorityName: "data:real",
            },
            {
              id: "data:check",
              label: "数据查看",
              authorityName: "data:check",
            },
            {
              id: "data:alert",
              label: "报警数据",
              authorityName: "data:alert",
            },
          ],
        },
        {
          id: "statistic",
          label: "数据统计",
          authorityName: "statistic",
          children: [
            {
              id: "statistic:integrity",
              label: "数据完整率",
              authorityName: "statistic:integrity",
            },
          ],
        },
        {
          id: "info",
          label: "信息管理",
          authorityName: "info",
          children: [
            {
              id: "info:area",
              label: "区域管理",
              authorityName: "info:area",
            },
            {
              id: "info:company",
              label: "企业管理",
              authorityName: "info:company",
              children: [
                {
                  id: "info:company-info",
                  label: "企业信息",
                  authorityName: "info:company-info",
                },
                {
                  id: "info:company-type",
                  label: "企业类型",
                  authorityName: "info:company-type",
                },
                {
                  id: "info:company-level",
                  label: "企业级别",
                  authorityName: "info:company-level",
                },
              ],
            },
            {
              id: "info:monitory-point",
              label: "监控点管理",
              authorityName: "info:monitory-point",
              children: [
                {
                  id: "info:monitory-point-info",
                  label: "监控点信息",
                  authorityName: "info:monitory-point-info",
                },
                {
                  id: "info:monitory-point-type",
                  label: "监控点类型",
                  authorityName: "info:monitory-point-type",
                },
              ],
            },
            {
              id: "info:pollution",
              label: "污染物管理",
              authorityName: "info:pollution",
              children: [
                {
                  id: "info:pollution-info",
                  label: "污染物信息",
                  authorityName: "info:pollution-info",
                },
              ],
            },
            {
              id: "info:message",
              label: "短信配置",
              authorityName: "info:message",
            },
          ],
        },
        {
          id: "room",
          label: "站房管理",
          authorityName: "room",
          children: [
            {
              id: "room:equipment",
              label: "设备管理",
              authorityName: "room:equipment",
            },
          ],
        },
        {
          id: "log",
          label: "日志管理",
          authorityName: "log",
          children: [
            {
              id: "log:datagram",
              label: "报文日志",
              authorityName: "log:datagram",
            },
            {
              id: "log:countercharge",
              label: "反控日志",
              authorityName: "log:countercharge",
            },
            {
              id: "log:api",
              label: "操作日志",
              authorityName: "log:api",
            },
            {
              id: "log:break",
              label: "断线日志",
              authorityName: "log:break",
            },
            {
              id: "log:message",
              label: "短信日志",
              authorityName: "log:message",
            },
            {
              id: "log:break-statistic",
              label: "断线统计",
              authorityName: "log:break-statistic",
            },
          ],
        },
        {
          id: "sys",
          label: "系统管理",
          authorityName: "sys",
          children: [
            {
              id: "sys:user",
              label: "用户管理",
              authorityName: "sys:user",
            },
            {
              id: "sys:role",
              label: "角色管理",
              authorityName: "sys:role",
            },
          ],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
        authorityName: "authorityName",
      },
    };
  },
  props: {
    dialogVisible: Boolean,
    roleId: Number,
  },
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {
      // 选中拥有的权限
      this.setTreeSelected();
    },
    onClose() {
      this.resetChecked();
      this.$emit("updateVisible");
    },
    close() {
      this.resetChecked();
      this.$emit("updateVisible");
    },
    // 设置选中
    setTreeSelected() {
      const params = {
        id: this.roleId,
      };
      getRoleAuthority(params).then((result) => {
        this.defaultCheckedKeys = this.$_.map(result.data, "authorityMark");
      });
    },
    // 清空选中
    resetChecked() {
      this.$refs.tree.setCheckedKeys([]);
    },
    handleConfirm() {
      const checkedNodes = this.$refs.tree
        .getCheckedNodes()
        .concat(this.$refs.tree.getHalfCheckedNodes());
      const authorities = this.$_.map(checkedNodes, "authorityName");
      console.log("分配的权限:", JSON.stringify(authorities));
      const requestBody = {
        id: this.roleId,
        authorities: authorities,
      };
      console.log(requestBody);
      bindingRoleAuthority(requestBody);
      this.close();
    },
  },
};
</script>

<style scoped></style>
