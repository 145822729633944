<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 系统管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>角色管理</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="5">
          <el-input size="mini" v-model="roleNameInput" placeholder="角色名">
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <el-button
          @click="handleAddRole"
          type="primary"
          icon="el-icon-edit"
          size="mini"
        >
          添加角色
        </el-button>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="roleName" label="角色名"> </el-table-column>
        <el-table-column prop="roleDescription" label="角色描述">
        </el-table-column>
        <el-table-column prop="enable" label="角色状态">
          <template #default="scope">
            <el-switch
              :disabled="scope.row.roleName === '系统管理员'"
              v-model="scope.row.enable"
              @click="handleSwitch(scope.$index, scope.row)"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createTime" label="创建时间"> </el-table-column>
        <el-table-column label="操作" width="180px">
          <template #default="scope">
            <el-button
              :disabled="scope.row.roleName === '系统管理员'"
              size="mini"
              type="text"
              @click="handleAddAuthority(scope.$index, scope.row)"
            >
              分配权限
            </el-button>
            <el-button
              :disabled="scope.row.roleName === '系统管理员'"
              size="mini"
              type="text"
              @click="handleEdit(scope.$index, scope.row)"
            >
              编辑
            </el-button>
            <el-button
              :disabled="scope.row.roleName === '系统管理员'"
              size="mini"
              type="text"
              @click="handleDelete(scope.$index, scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
    <role-add-dialog
      :dialog-visible="dialogVisibleRoleAdd"
      @updateVisible="closeAddRole"
    >
    </role-add-dialog>
    <role-edit-dialog
      :dialog-visible="dialogVisibleRoleEdit"
      :row="editRow"
      @updateVisible="closeEditRole"
    ></role-edit-dialog>
    <authority-tree
      :dialog-visible="dialogVisibleAuthorityTree"
      :role-id="editRow.id"
      @updateVisible="closeAuthorityTree"
    >
    </authority-tree>
  </div>
</template>

<script>
import RoleAddDialog from "@/views/sys/role/dialog/RoleAdd";
import RoleEditDialog from "@/views/sys/role/dialog/RoleEdit";
import AuthorityTree from "@/views/sys/role/dialog/AuthorityTree";
import { deleteRole, getTable, switchRoleStatus } from "@/api/role";

export default {
  name: "Role",
  components: { AuthorityTree, RoleEditDialog, RoleAddDialog },
  props: [],
  data() {
    return {
      roleNameInput: "",
      dialogVisibleRoleAdd: false,
      dialogVisibleRoleEdit: false,
      dialogVisibleAuthorityTree: false,
      editRow: {},
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
      tableData: [],
      role: {},
      rules: {},
    };
  },
  computed: {},
  watch: {},
  created() {
    this.getList();
  },
  mounted() {},
  methods: {
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleClear() {
      this.roleNameInput = "";
    },
    handleSearch() {
      console.log(this.roleNameInput);
      this.getList();
    },
    handleAddRole(index, row) {
      console.log(index, row);
      this.dialogVisibleRoleAdd = true;
    },
    handleAddAuthority(index, row) {
      console.log(index, row);
      this.dialogVisibleAuthorityTree = true;
      this.editRow = row;
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.dialogVisibleRoleEdit = true;
      this.editRow = JSON.parse(JSON.stringify(row));
    },
    handleDelete(index, row) {
      console.log(index, row);
      this.$confirm("此操作将永久删除该角色, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          const params = {
            id: row.id,
          };
          deleteRole(params).then(() => {
            this.getList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleSwitch(index, row) {
      if (row.roleName === "系统管理员") {
        return;
      }
      console.log(index, row);
      const params = {
        id: row.id,
        status: row.enable ? 1 : 0,
      };
      switchRoleStatus(params);
    },
    closeAddRole() {
      this.dialogVisibleRoleAdd = false;
    },
    closeEditRole() {
      this.dialogVisibleRoleEdit = false;
    },
    closeAuthorityTree() {
      this.dialogVisibleAuthorityTree = false;
    },
    getList() {
      const param = {
        roleNameInput: this.roleNameInput,
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
      };
      console.log("请求参数:", JSON.stringify(param));
      getTable(param).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        data.list.forEach((item) => {
          item.enable = item.enable === 1;
        });
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
