<template>
  <div>
    <el-dialog
      v-model="visible"
      @open="onOpen"
      @close="onClose"
      title="添加角色"
    >
      <el-form
        ref="elForm"
        :model="formData"
        :rules="rules"
        size="mini"
        label-width="px"
        label-position="left"
      >
        <el-form-item label-width="80px" label="角色名" prop="roleName">
          <el-input
            v-model="formData.roleName"
            placeholder="请输入单行文本角色名"
            clearable
            :style="{ width: '100%' }"
          >
          </el-input>
        </el-form-item>
        <el-form-item
          label-width="80px"
          label="角色描述"
          prop="roleDescription"
        >
          <el-input
            v-model="formData.roleDescription"
            placeholder="请输入单行文本角色描述"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item
          label-width="80px"
          label="角色状态"
          prop="enable"
          required
        >
          <el-switch v-model="formData.enable"></el-switch>
        </el-form-item>
      </el-form>
      <div>
        <slot name="footer">
          <el-button @click="close">取消</el-button>
          <el-button type="primary" @click="handleConfirm">确定</el-button>
        </slot>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { addRole } from "@/api/role";

export default {
  name: "RoleAddDialog",
  components: {},
  props: { dialogVisible: Boolean },
  data() {
    return {
      visible: false,
      formData: {
        roleName: undefined,
        roleDescription: undefined,
        enable: true,
      },
      rules: {
        roleName: [
          {
            required: true,
            message: "请输入单行文本角色名",
            trigger: "blur",
          },
        ],
        roleDescription: [
          {
            required: true,
            message: "请输入单行文本角色描述",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {},
  watch: {
    dialogVisible: {
      handler(value) {
        this.visible = value;
      },
    },
  },
  created() {},
  mounted() {
    this.visible = this.dialogVisible;
  },
  methods: {
    onOpen() {},
    onClose() {
      this.$refs["elForm"].resetFields();
      this.$emit("updateVisible");
    },
    close() {
      this.$emit("updateVisible");
    },
    handleConfirm() {
      this.$refs["elForm"].validate((valid) => {
        if (!valid) {
          return;
        }
        if (this.formData.enable === true) {
          this.formData.enable = 1;
        } else {
          this.formData.enable = 0;
        }
        const requestBody = this.formData;
        addRole(requestBody).then(() => {
          this.$parent.getList();
        });
        this.close();
      });
    },
  },
};
</script>
<style></style>
